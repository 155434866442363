import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/4F/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/4F/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/4F/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/4F/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly4F2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
          {
                    src: p4,
                    source: p4,
                    rows: 1,
                    cols: 2,
                },
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 2,
    }, 
    {
      src: p1,
      source: p1,
      rows: 1.5,
      cols: 2,
    },

    
    {
          src: p2,
          source:p2,
          rows: 1,
          cols: 2,
        },
   
     
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Ganesh Chaturthi	
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4F  Date:21 September 2023 & 22 September 2023
  <br/><br/>
        </Typography>

        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 4F presented their assembly on the topic, ‘Ganesh Chaturthi’ on Thursday, 21 September 2023 and Friday, 22 September 2023. The students highlighted that this auspicious festival brings in the festive fervour that unites people of different languages, cultures and traditions to celebrate. The assembly commenced with a brief introduction about the significance of the festival and a skit on eco-friendly Ganesh Chaturthi. 
        <br></br>
        The main objective was to focus on eco-friendly celebrations, encouraging the use of biodegradable materials and eco-conscious practices for idol immersion to protect the environment. Students also emphasized on the environmental responsibility fostering harmonious relationship between the planet and its people.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Let us all celebrate the pious occasion by using eco-friendly idol which nurtures the nature.”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4F2023;